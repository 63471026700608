<div class="toolbar" id="header">
    <div class="container">
      <span class="toolbarItem" *ngFor="let item of header | keyvalue" [ngSwitch]="item.value.type">
        <img *ngSwitchCase="'image'" width="{{ item.value.width }}" alt="{{ item.value.alt }}"
             src="{{ item.value.src }}" style="{{ item.value.style }}" />
        <a *ngSwitchCase="'link'" target="_blank" href="{{ item.value.href }}" style="{{ item.value.style }}">{{ item.value.text }}</a>
        <span *ngSwitchCase="'text'" style="{{ item.value.style }}">{{ item.value.text }}</span>
      </span>
    </div>
  </div>
<div>
    <div class="container">
        <div class="row">
            <a href="" click="onBack()" class="back-button"><span class="chevron"></span>Back</a>
            <h5 class="card-title acc-validation">Account Validation</h5>
            <div class="card custom-card ba-maintenance">
                <div class="container-fluid acc-verif">
                    <input type="radio" name="tabs" id="validation" [checked]="selectedTab === 'validation'" />
                    <label for="validation">Account Validation</label>

                    <input type="radio" name="tabs" id="history"
                        [checked]="selectedTab === 'history'"
                        [disabled]="enableHistoryTab ? false: true"
                        [ngStyle]="{'color': enableHistoryTab ? 'black': 'grey'}" />
                    <label for="history" [ngClass]="enableHistoryTab ? 'hist-enabled' : 'hist-disabled'" (click)="onHistoryTabClick()">Validation History</label>
                    <div class="tab validation">
                        <app-account-validation></app-account-validation>
                    </div>
                    <div class="tab history" *ngIf="selectedTab === 'history'">
                        <app-account-history></app-account-history>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
